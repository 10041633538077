import {breakpointsTailwind, useBreakpoints} from "@vueuse/core";

export function useViewport() {
    const breakpoints = useBreakpoints(breakpointsTailwind)
    const activeBreakpoint = breakpoints.active()
    const isTablet = computed(() => ['md'].includes(activeBreakpoint.value || ''))
    const isMobile = computed(() => ['xs','sm'].includes(activeBreakpoint.value || '') || !activeBreakpoint.value)
    const isTabletOrMobile = computed(() => isTablet.value || isMobile.value)

    return {
        breakpoints,
        activeBreakpoint,
        isTablet,
        isTabletOrMobile,
        isMobile,
    }
}
